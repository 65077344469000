import { useEffect } from "react";

export default function DMCA() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'DMCA Policy | Merge Melons';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Learn about Merge Melons' DMCA policy, our commitment to protecting intellectual property, and our guide to handling content copyright infringement claims on our platform.");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Merge Melons - Play Watermelon Drop Game Online Unblocked';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Experience the excitement with Watermelon drop Game – a creative blend of strategy and entertainment. Discover Merge Melons now to challenge your skills!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://mergemelon.com/');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-14vh)] lg:min-h-[calc(100vh-15vh)] md:min-h-[calc(100vh-10vh)] mb-4 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        Merge Melons DMCA Policy
                    </h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        At Merge Melons, we respect the intellectual property rights of others and expect our users to do the same. In accordance with the Digital Millennium Copyright Act (DMCA), we have established the following procedure for handling copyright infringement claims:
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        1. Copyright Infringement Notification
                    </h2>
                    <br />
                    <div className="2xl:text-lg lg:text-lg text-base">
                        If you believe that content on Merge Melons infringes upon your copyright, please provide the following information:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Electronic or physical signature of the copyright owner or an authorized representative.
                            </li>
                            <li>
                                Description of the copyrighted work that you claim has been infringed.
                            </li>
                            <li>
                                Description of the location of the infringing content on our website.
                            </li>
                            <li>
                                Your contact information: address, phone number, and email.
                            </li>
                            <li>
                                A statement that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.
                            </li>
                            <li>
                                A statement that the information in your notice is accurate and, under penalty of perjury, that you are the copyright owner or authorized to act on the owner's behalf.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        2. How to Submit a Notification
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Please send your copyright infringement notice to the following email address: <a href="mailto:support@mergemelon.com" className=" text-blue-500">{`support@mergemelon.com`}</a>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        3. Response to Notification
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Upon receiving a valid notification, we will:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Remove or disable access to the allegedly infringing content.
                            </li>
                            <li>
                                Notify the user who posted the content about its removal.
                            </li>
                            <li>
                                If we receive a counter-notification from the user, we will review it and may restore the content if appropriate.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        4. Counter-Notification
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        If you believe that your content was removed due to a mistake or misidentification, you may submit a counter-notification with the following information:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Your electronic or physical signature.
                            </li>
                            <li>
                                Description of the content that was removed and its prior location on the website.
                            </li>
                            <li>
                                A statement, under penalty of perjury, that you have a good faith belief that the content was removed due to a mistake or misidentification.
                            </li>
                            <li>
                                Your name, address, phone number, and email.
                            </li>
                            <li>
                                A statement that you consent to the jurisdiction of the federal court in your district (or where we are located if you are outside the United States) and that you will accept service of process from the person who provided the original infringement notification.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        <span className="italic">Send your counter-notification to</span> <a href="mailto:support@mergemelon.com" className=" text-blue-500">{`support@mergemelon.com`}</a>
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Note: Information in DMCA notifications may be shared with involved parties to assist in resolving the claim. Please consult with an attorney before submitting a notification to understand your rights and obligations.
                    </div>
                </div>
            </div>
        </div>
    )
}
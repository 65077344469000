import { useEffect, useState } from "react";
// import Popular from "./popular/page";
import { FaPlay } from "react-icons/fa";

function useMobilePlatform() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobilePlatforms = /android|iphone|ipod|windows phone|blackberry/i;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    setIsMobile(mobilePlatforms.test(userAgent) || isIOS);
  }, []);

  return isMobile;
}

export default function Home() {
  const isMobile = useMobilePlatform();

  const handleFullScreen = () => {
    const iframe = document.getElementById("game-iframe");
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
  };

  const handleClick = () => {
    window.location.href = "#/game";
  };

  return (
    <div className="relative z-50 bg-[#FFEEF1]">
      {!isMobile ?
        <div className="2xl:flex lg:flex flex items-center justify-center 2xl:h-[85vh] lg:h-[75vh] h-[65vh] lg:mx-24 mx-8">
          <iframe
            id="game-iframe"
            src="https://merge-melons.gamestores.fun/"
            width="100%"
            height="100%"
            frameBorder="0"
            title=" Merge Melons"
            style={{
              aspectRatio: "16 / 9",
              objectFit: "cover",
              borderRadius: "8px",
              maxWidth: "fit-content",
              border: "2px solid #FF6A84",
            }}
          ></iframe>
        </div>
        :
        <div className="flex flex-col items-center justify-center h-[35vh] lg:mx-24 mx-8 opacity-90 rounded-lg">
          <div className="relative w-full h-full flex flex-col items-center justify-center">
            <div className="absolute inset-0 bg-playnow opacity-50"></div>
            <button className="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center relative animate-zoom" onClick={handleClick}>
              <FaPlay className="text-black w-6 h-6" />
            </button>
            <a className="poppins-bold font-bold text-[20px] text-white mt-3 relative"
              href="#/game"
              style={{ textShadow: "1px 1px 2px black, -1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black" }}
            >Play now</a>
          </div>

        </div>
      }
      <div className="flex items-center justify-center">
        <div className="container">
          {!isMobile && <div className="2xl:flex lg:flex md:hidden hidden justify-center mt-6 2xl:mb-[40px] lg:mb-[28px] mb-[28px]">
            <button
              onClick={handleFullScreen}
              className="btn-full-screen 2xl:py-6 2xl:px-16 lg:py-3 lg:px-8 md:py-3 md:px-8 px-4 py-2 rounded"
            >
              <span className="lilita-one-regular 2xl:text-4xl lg:text-3xl md:text-3xl text-white">
                Full Screen
              </span>
            </button>
          </div>}
          {/* <Popular /> */}
          <div className='w-full 2xl:pt-1 lg:pt-1 pt-10 bg-[#F8FFFF]'>
            <div className='2xl:mx-56 2xl:mt-14 2xl:pb-14 md:pb-14 md:mt-14 mt-10 lg:mx-20 md:mx-9 mx-6 pb-10'>
              <h1 className='2xl:text-[40px] md:text-[40px] text-[32px] lilita-one-regular text-[#FF6A84] mt-6'>Merge Melons: The Ultimate Relaxation Game!</h1>
              <div className='2xl:mt-6 md:mt-6'>
                <div className='text-base md:text-lg 2xl:text-lg lg:text-lg poppins-regular text-[#383838]'>
                  If you’re looking for a game that’s both simple and entertaining, Merge Melons is your perfect choice. With innovative gameplay, vibrant visuals, and exciting features, this game promises hours of fun you won’t want to miss.
                </div>
              </div>
            </div>
          </div>

          <div className="text-[#383838] lg:px-20 md:px-9 px-6 2xl:pt-20 lg:pt-20 md:pt-14 content-des 2xl:px-56 2xl:pb-20 md:pb-14 pt-14 pb-14">
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              What Is Merge Melons?
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Merge Melons is an engaging merge game where your mission is to combine identical melons to create new, unique ones. The game challenges not only your quick reflexes but also your creativity and strategic thinking.
              {/* <ul style={{
                listStyleType: 'disc',
                marginLeft: '30px'
              }}>
                <li>
                  Navigating through the lush landscapes of Merge Melons is a breeze thanks to intuitive controls designed for players of all levels. Here's a quick guide:
                </li>
              </ul> */}
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              How to Play Merge Melons
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Playing Merge Melons is incredibly simple:
              <ul style={{
                listStyleType: 'disc',
                marginLeft: '30px',
                marginTop: '10px'
              }}>
                <li>
                  Drag and Drop: Drag identical melons into the same spot to merge them.
                </li>
                <li>
                  Create New Melons: Each successful merge unlocks a new type of melon with an exciting design.
                </li>
                <li>
                  Score Points: The higher your score, the more melons and levels you can unlock.
                </li>
                <li>
                  The difficulty increases with each level, requiring you to be more skillful and strategic to win.
                </li>
              </ul>
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Why Is Merge Melons So Addictive?
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Merge Melons isn’t just a game; it’s a fun and relaxing experience with numerous standout features:
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-2xl text-xl md:text-xl">
              Vibrant and Eye-Catching Graphics
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              The game boasts colorful, lively visuals. The melons are designed to look fresh and appealing, providing a satisfying feeling every time you successfully merge them.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-2xl text-xl md:text-xl">
              Lively Sound Effects
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              The dynamic sound effects enhance the gameplay experience, helping you unwind after a long day.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-2xl text-xl md:text-xl">
              Diverse Challenges
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              With various levels ranging from easy to challenging, Merge Melons caters to players of all ages. There’s always a new goal to achieve, keeping boredom at bay.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-xl lg:text-2xl text-xl md:text-xl">
              Play Anytime, Anywhere
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Whether you’re at home, at work, or on the go, Merge Melons is accessible on all devices and doesn’t require an internet connection.
            </div>
            <br />
            <h2 className="lilita-one-regular 2xl:text-2xl lg:text-2xl text-2xl md:text-2xl">
              Tips to Score High in Merge Melons
            </h2>
            <br />
            <div className="2xl:text-lg lg:text-lg text-base poppins-regular">
              Want to get a high score in Merge Melons? Try these tips:
              <ul
                style={{
                  listStyleType: "disc",
                  marginLeft: "30px",
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
              >
                <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4 poppins-regular">
                  Focus on Merging Larger Melons: Creating new types of melons yields more points.
                </li>
                <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start poppins-regular">
                  Manage Space Wisely: Don’t let the board fill up—use space strategically.
                </li>
                <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start poppins-regular">
                  Make the Most of Your Time: If the level is timed, stay focused and act quickly to merge efficiently.
                </li>
              </ul>
              Merge Melons is not just a game; it’s a way to spark your creativity, train your mind, and enjoy meaningful moments of relaxation.
            </div>

            <div className="control flex items-center 2xl:mt-10 lg:mt-10 mt-4 md:mt-10">
              <div className="2xl:pl-8 2xl:py-8 lg:pl-8 lg:py-8 px-8 py-8">
                <h2 className="2xl:text-2xl lg:text-2xl md:text-2xl text-2xl font-bold text-start lilita-one-regular">
                  Conclusion of the game
                </h2>
                <ul
                  style={{
                    listStyleType: "disc",
                    marginLeft: "30px",
                  }}
                >
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4 poppins-regular">
                    With simple yet addictive gameplay, diverse melons, and social integration, this is a game that pushes the boundaries of conventional entertainment. So dive into the fruit madness, hone your merge skills, and let the melon madness begin!
                  </li>
                  <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start poppins-regular">
                    Remember, the fun of Merge Melons lies not only in matching fruits but also in piecing together enjoyable moments in your gaming journey. It's not just a game it's an adventure full of melons waiting for you to explore that we especially have for you.
                  </li>
                </ul>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}

import { useEffect } from "react";

export default function About() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'About Us - Merge Melons';
        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', 'Discover Merge Melons, a free online fruit matching game featuring simple gameplay, vibrant graphics, and suitable for all ages.');
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Merge Melons - Play Watermelon Drop Game Online Unblocked';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Experience the excitement with Watermelon drop Game – a creative blend of strategy and entertainment. Discover Merge Melons now to challenge your skills!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://mergemelon.com/');
            }
        };
    }, []);
    return (
        <div className="content-body mb-10 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="container 2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">About Merge Melons</h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        If you're looking for a casual game that's easy to play yet highly engaging, Merge Melons is the perfect choice. This fun and colorful fruit-matching game lets you unleash your creativity and challenge yourself with exciting levels. Let’s dive into everything you need to know about Merge Melons in this detailed introduction!
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        What is Merge Melons?
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Merge Melons is a captivating fruit-matching game designed to offer maximum relaxation and entertainment. In this game, you'll match identical fruits to create larger ones, score as many points as possible, and conquer challenging levels.
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        The highlight of the Watermelon game lies in its simple yet incredibly addictive gameplay. Whether you're a beginner or an experienced gamer, Merge Melons offers moments of excitement that never get boring.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Why is Merge Melons So Popular?
                    </h2>
                    <br />
                    <h2 class="about-game  2xl:text-xl lg:text-xl md:text-xl text-base">
                        1. Simple and Accessible Gameplay
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Anyone can start playing immediately without needing time to learn. Just drag and drop!
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-xl lg:text-xl md:text-xl text-base">
                        2. Stunning and Vibrant Graphics
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Watermelon game stands out with its bright graphics, featuring vividly designed fruits like watermelons, oranges, and apples. This not only attracts players but also creates a relaxing atmosphere.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-xl lg:text-xl md:text-xl text-base">
                        3. Highly Challenging
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        The game is not just about dragging and dropping; it also requires you to strategize to optimize the space on your board and avoid getting “stuck.”
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-xl lg:text-xl md:text-xl text-base">
                        4. Suitable for All Ages
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Merge Melons is the perfect game for everyone, from kids to adults, office workers, or anyone who loves casual games.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        How to Play Merge Melons
                    </h2>
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Merge Melons has very simple gameplay, but achieving a high score is no easy task. Here’s a basic guide:
                        <ul
                            style={{
                                listStyleType: "disc",
                                marginLeft: "30px",
                                marginTop: '10px',
                                marginBottom: '10px'
                            }}
                        >
                            <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4">
                                Drag and Drop: Select and drag the fruits on the board to a suitable position.
                            </li>
                            <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start">
                                Merge: When two identical fruits touch, they combine into a larger fruit.
                            </li>
                            <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start">
                                Manage Space: Arrange fruits so you don’t run out of space on the board.
                            </li>
                            <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start  ">
                                Maximize Your Score: Try to create the longest matching chains to earn the highest score.
                            </li>
                        </ul>
                        The game ends if there is no more space to move or merge fruits, so plan every move carefully and strategically!
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        What Makes Merge Melons Special?
                    </h2>
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        <ul
                            style={{
                                listStyleType: "disc",
                                marginLeft: "30px",
                            }}
                        >
                            <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4  ">
                                No Time Limit: Merge Melons offers a relaxing experience without any pressure, allowing you to play at your own pace.
                            </li>
                            <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start  ">
                                Regular Updates: The game is constantly improved with new features to enhance the player experience.
                            </li>
                            <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start  ">
                                Multi-Platform Support: Play Merge Melons online or download it on both Android and iOS.
                            </li>
                            <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start  ">
                                Leaderboard Integration: Compete with friends or other players to see who scores the highest
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Benefits of Playing Merge Melons
                    </h2>
                    <br />
                    <h2 class="about-game  2xl:text-xl lg:text-xl md:text-xl text-base">
                        1. Entertainment and Stress Relief
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Merge Melons is the perfect choice for unwinding after a long day of work or study. The soothing music combined with vibrant visuals creates a comfortable and enjoyable experience.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-xl lg:text-xl md:text-xl text-base">
                        2. Develop Logical Thinking
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        The game challenges you to think strategically and manage your space, sharpening your logical thinking and focus.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-xl lg:text-xl md:text-xl text-base">
                        3. Connect with Others
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Merge Melons is not just a solo game but also allows you to connect with friends through leaderboards and shared achievements.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Tips to Master Merge Melons
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        To achieve high scores in Merge Melons, keep these tips in mind:
                        <ul
                            style={{
                                listStyleType: "disc",
                                marginLeft: "30px",
                            }}
                        >
                            <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start mt-4  ">
                                Think Before You Move: Avoid rushing; plan carefully to maximize space efficiency.
                            </li>
                            <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start  ">
                                Create Long Combos: The longer the fruit chain you create, the faster your score will grow.
                            </li>
                            <li className="2xl:text-lg lg:text-lg md:text-lg text-lg text-start  ">
                                Prioritize Smaller Fruits: This helps you save space and open up opportunities to merge larger fruits.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Merge Melons – A Game You Can’t Miss
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        With its perfect blend of simple gameplay, stunning graphics, and challenging levels, Merge Melons has become one of the most loved games among players worldwide. Whether you want quick entertainment or a challenging puzzle, Merge Melons is ready to deliver an amazing experience.
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Visit this website today to explore the colorful world of fruits and start your journey to conquer Merge Melons!
                    </div>
                </div>
            </div>
        </div>
    )
}
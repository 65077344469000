import { useEffect } from "react";

export default function ContactUs() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Contact Us | Merge Melons';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Contact the Merge Melons team for support and questions about the game. We are always willing to listen and respond to you as soon as possible.");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Merge Melons - Play Watermelon Drop Game Online Unblocked';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Experience the excitement with Watermelon drop Game – a creative blend of strategy and entertainment. Discover Merge Melons now to challenge your skills!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://mergemelon.com/');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-14vh)] lg:min-h-[calc(100vh-15vh)] md:min-h-[calc(100vh-10vh)] mb-4 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">Contact Merge Melons</h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        At Merge Melons, we are always ready to listen and assist you. If you have any questions, feedback, or need support related to the game, please contact us through the following channels:
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Contact Information
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Email: <a href="mailto:support@mergemelon.com" className=" text-blue-500">{`support@mergemelon.com`}</a>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        Response Time
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We commit to responding to you within 24 working hours from the time we receive your message.
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Thank you for your interest in Merge Melons. We look forward to your feedback to continually improve and provide a better experience for players.
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useEffect } from "react";

export default function TermOfUse() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Term of Use | Merge Melons';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Read Merge Melons' Terms of Use to understand your rights and responsibilities when participating in the game. We are committed to providing a safe and fair experience for all players.");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Merge Melons - Play Watermelon Drop Game Online Unblocked';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Experience the excitement with Watermelon drop Game – a creative blend of strategy and entertainment. Discover Merge Melons now to challenge your skills!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://mergemelon.com/');
            }
        };
    }, []);

    return (
        <div className="content-body 2xl:min-h-[calc(100vh-18vh)] text-[#383838] mb-10">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        Term of Use  for Merge Melons
                    </h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Welcome to Merge Melons! Before you start enjoying the game, please read the following terms of use carefully. Accessing and using Merge Melons signifies your agreement to comply with these terms.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        1. Acceptance of Terms
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        By accessing and using Merge Melons, you agree to adhere to these terms of use. If you disagree with any part of these terms, please discontinue using the game.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        2. Intellectual Property Rights
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        All content, including but not limited to text, images, audio, graphics, and source code in Merge Melons, is owned by us and protected by copyright laws. You may not copy, distribute, or use any part of the game without our written permission.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        3. User Conduct
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        When participating in Merge Melons, you commit to:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Not using the game for illegal purposes.
                            </li>
                            <li>
                                Not interfering with the operation of the game or its servers.
                            </li>
                            <li>
                                Not using software or tools to cheat or disrupt the experience of other players.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        4. User Accounts
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        You may be required to create an account to access certain features of Merge Melons. You are responsible for maintaining the confidentiality of your login information and all activities that occur under your account. Please notify us immediately if you detect any unauthorized activity.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        5. Right to Modify Terms
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We reserve the right to change or update these terms of use at any time. Changes will take effect immediately upon posting. Continuing to use Merge Melons after changes signifies your acceptance of the new terms.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        6. Limitation of Liability
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Merge Melons is provided "as is" and "as available." We do not guarantee that the game will be error-free or uninterrupted. To the extent permitted by law, we are not liable for any damages arising from the use or inability to use Merge Melons.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        7. Contact
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        If you have any questions or concerns about these terms of use, please contact us at:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Email: <a href="mailto:support@mergemelon.com" className=" text-blue-500">{`support@mergemelon.com`}</a>
                            </li>
                        </ul>
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Thank you for choosing Merge Melons. We aim to provide you with a fair and enjoyable gaming experience.
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useEffect } from "react";

export default function Policy() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.title = 'Privacy Policy | Merge Melons';

        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', "Learn how Merge Melons collects, uses and protects your personal information in our Privacy Policy. We are committed to ensuring privacy and data security for all players.");
        }
        const linkCanonical = document.querySelector("link[rel='canonical']");
        if (linkCanonical) {
            linkCanonical.setAttribute('href', window.location.href);
        }
        return () => {
            document.title = 'Merge Melons - Play Watermelon Drop Game Online Unblocked';
            if (metaDescription) {
                metaDescription.setAttribute('content', 'Experience the excitement with Watermelon drop Game – a creative blend of strategy and entertainment. Discover Merge Melons now to challenge your skills!');
            }
            if (linkCanonical) {
                linkCanonical.setAttribute('href', 'https://mergemelon.com/');
            }
        };
    }, []);

    return (
        <div className="content-body mb-10 text-[#383838]">
            <div className="flex items-center justify-center">
                <div className="2xl:px-40 lg:px-24 px-6 relative z-50 mt-6">
                    <h1 className="text-3xl font-bold text-center">
                        Privacy Policy for Merge Melons
                    </h1>
                    <br />
                    <span class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        At Merge Melons, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you interact with our game.
                    </span>
                    <br />
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        1. Information We Collect
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Personal Information: When you register or contact us, we may collect details such as your name, email address, and phone number.
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                Technical Data: This includes your IP address, device type, operating system, and browser type.
                            </li>
                            <li>
                                Usage Data: Information about your gameplay, preferences, and interactions within Merge Melons.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        2. How We Use Your Information
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        To Enhance Gameplay: Personalize your experience and provide tailored content.<br />
                        Communication: Send updates, respond to inquiries, and provide support.<br />
                        Analytics: Monitor and analyze usage to improve our services.<br />
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        3. Data Sharing and Disclosure
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We do not sell or rent your personal information. We may share data with:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px'
                        }}>
                            <li>
                                Service Providers: Trusted partners who assist in operating our game and services.
                            </li>
                            <li>
                                Legal Obligations: When required by law or to protect our rights.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        4. Data Security
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We implement robust security measures to protect your information from unauthorized access, alteration, or disclosure.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        5. Your Rights
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        You have the right to:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px'
                        }}>
                            <li>
                                Access: Request a copy of the personal data we hold about you.
                            </li>
                            <li>
                                Correction: Update or correct your information.
                            </li>
                            <li>
                                Deletion: Request the deletion of your data, subject to legal obligations.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        6. Children's Privacy
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        Merge Melons is not intended for children under 13. We do not knowingly collect data from children under this age.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        7. Changes to This Policy
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        We may update this Privacy Policy periodically. We will notify you of any significant changes by posting the new policy on our website.
                    </div>
                    <br />
                    <h2 class="about-game  2xl:text-2xl lg:text-2xl md:text-2xl text-base">
                        8. Contact Us
                    </h2>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        If you have questions or concerns about this Privacy Policy, please contact us at:
                        <ul style={{
                            listStyleType: 'disc',
                            marginLeft: '30px',
                            marginTop: '10px',
                            marginBottom: '10px'
                        }}>
                            <li>
                                Email: <a href="mailto:support@mergemelon.com" className=" text-blue-500">{`support@mergemelon.com`}</a>
                            </li>
                        </ul>
                    </div>
                    <br />
                    <div class="des-game  2xl:text-lg lg:text-lg md:text-lg text-base">
                        <span className="italic">By using Merge Melons, you agree to this Privacy Policy.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}